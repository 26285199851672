import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import ResourcesAndSupportSubmenu from "../../components/template-partials/resources-and-support/resources-and-support-submenu"

import { UpNextCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"

import college from "../../images/7.4-college.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"

const SupportAndAdvocacyPage = () => {
  return (
    <Layout>
      <SEO
        title="Neuroblastoma Support Groups and Advocacy"
        description="Helpful information about neuroblastoma advocacy groups. Find support for all stages of your child’s treatment journey and beyond."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Neuroblastoma Support and Advocacy Groups"
      >
        <h1 className="section__heading section__heading--green h1">
          Neuroblastoma Support and Advocacy Groups
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Resources`}>
              <ResourcesAndSupportSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
              <h2 className="tc-barney h2 jost-med" style={{margin: "0rem"}}>
              Neuroblastoma support and advocacy groups
                </h2>
                <p style={{ margin: "0 0 2rem 0"}}>
              The groups below provide helpful information and support for
              families like yours who are coping with a childhood cancer
              diagnosis.
            </p>
                <h3 className="tc-barney h3 jost-med">CAC2 - Hope Portal</h3>
                <p>
                  A cloud-based tool designed to assist families and healthcare
                  professionals in their search for childhood cancer support
                  services and information.”
                </p>
                <iframe
                  src="https://d1mdgshk1lehk7.cloudfront.net/hope-portal/"
                  style={{
                    width: "100%",
                    minHeight: "500px",
                    marginTop: "2px",
                    opacity: "1",
                    visibility: "visible",
                  }}
                ></iframe>
                <em>
                  United Therapeutics Corporation is not affiliated with the
                  featured organization. The information provided by the
                  organization is meant for informational purposes only
                </em>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Candlelighters Childgood Cancer Family Alliance"
                target="_blank"
                rel="noreferrer"
                href="https://candle.org/"
              >
                Candlelighters Childhood Cancer Family Alliance{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Provides emotional, educational, and practical support to families
              of children with cancer.
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="End Kids Cancer Foundation"
                target="_blank"
                rel="noreferrer"
                href="https://www.endkidscancer.org/"
              >
                End Kids Cancer Foundation{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Supports research that harnesses the immune system, and offers
              resources for finding care and overcoming financial and emotional
              hardships.
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="The Evan Foundation"
                target="_blank"
                rel="noreferrer"
                href="https://www.theevanfoundation.org/"
              >
                The Evan Foundation{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Supports research, and sponsors the Treats & Treasures Carts
              Program at select hospitals and clinics across the country.
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Solving Kids Cancer"
                target="_blank"
                rel="noreferrer"
                href="https://solvingkidscancer.org/"
              >
                Solving Kids’ Cancer{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Finds, funds, and advocates for breakthrough childhood cancer
              treatments.
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Mattie Miracle"
                target="_blank"
                rel="noreferrer"
                href="https://www.mattiemiracle.com/"
              >
                Mattie Miracle{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Works to ensure that children fighting cancer and their families
              have a psychosocial voice, and that their psychological needs are
              addressed.
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="The Still Strong Foundation"
                target="_blank"
                rel="noreferrer"
                href="https://stillstrongfoundation.org/"
              >
                The Still Strong Foundation{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
              <br />
              Founded by{" "}
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Devon and Leah Still"
                target="_blank"
                rel="noreferrer"
                href="https://stillstrongfoundation.org/about-ssf/our-story/"
              >
                Devon and Leah Still
              </a>
              , the Still Strong Foundation helps provide financial support to
              struggling families of children with cancer.
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div id="college" className="row row--inner align-justify align-middle">
          <div className="columns large-4 large-text-left small-12 text-center">
            <FancyImage
              url={college}
              alt="Is your child a survivor getting ready for college?"
            />
          </div>
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Is your child a survivor getting ready for college?
            </h3>
            <p>Find college scholarships for survivors:</p>
            <ul className="ul--standard">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Ambassador Scholarship Program"
                  href="https://www.thenccs.org/scholarship/"
                >
                  The National Children’s Cancer Society Beyond the Cure
                  Ambassador Scholarship Program{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="CCC Scholars Program"
                  href="https://www.childrenscancercause.org/scholars?gclid=Cj0KCQjwoJX8BRCZARIsAEWBFMKjZW0vrnlua1iBCYl1SN7pS49Ugo9X21z7pBq5B3FANEyZi31uMPYaAnE0EALw_wcB"
                >
                  Children’s Cancer Cause (CCC) College Scholars Program{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="National Collegiate Cancer Foundation"
                  href="http://collegiatecancer.org/scholarships/"
                >
                  The National Collegiate Cancer Foundation{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="The Evan Lindberg and Erik Ludwinski College Scholarship Program for Childhood Cancer Survivors "
                  href="https://www.theevanfoundation.org/scholarship-information "
                >
                  The Evan Lindberg and Erik Ludwinski College Scholarship
                  Program for Childhood Cancer Survivors{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Skivolo's Kid Zone"
          title="Skivolo's Kid Zone"
          lead="Explore the neuroblastoma journey through the eyes of Skivolo, a young red panda, and his family by downloading storybooks and coloring apps."
          url="/resources/skivolos-kid-zone/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default SupportAndAdvocacyPage
